<template>
  <Ethereum :callback="initialize" />
  <div v-if="address">
    yellow wurld
  </div>
  <ConnectWallet v-else />
</template>
<script>
'use strict';
import Ethereum from '/src/components/common/Ethereum.vue';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';

import { ref, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
    // Button,
    // TextInput,
    // NFTCard,
    // LoadingSpinnerIcon,
    Ethereum,
    ConnectWallet
  },

  setup(props, context) {
    const store = useStore();
    //const address = ref('');

    const initialize = async () => {
      let user = store.state.ethers.address;
      
      // // let collectionAddress = '';
      // if (store.state.ethers.canSign) {
      //   await store.dispatch(
      //     'mint/getItems',
      //     { address, collectionAddress: collectionAddress, groupId },
      //     { root: true }
      //   );
      // }
    };

    const address = computed(()=> {
      return store.state.ethers.address;
    });

    return {
      initialize,
      address
    };
  }
};
</script>
<style scoped lang="scss"></style>
