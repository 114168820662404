<template>
  <Ethereum :callback="() => {}" />
  <transition name="fade" mode="out-in">
    <div
      class="wallet-modal-container"
      v-if="store.state.alert.showWalletModal"
    >
      <WalletModal @exit="hideWalletModalDisplay" />
    </div>
  </transition>

  <div class="navBar">
    <div class="container">
      <div class="menuIcon" @click="showMobileMenu">
        <div class="line"></div>
        <div class="line"></div>
      </div>

      <MobileMenu
        :isVisible="mobileMenuIsVisible"
        @toggleVisibility="mobileMenuIsVisible = false"
        @showWalletModal="showWalletModalDisplay"
      />

      <button v-if="store.state.ethers.address" class="address">
        <div>{{ profileAddressLabel }}</div>
      </button>
      <!-- The user is not connected to a signing provider. -->
      <button
        class="address"
        v-if="!store.state.ethers.address"
        @click="showWalletModalDisplay"
      >
        Connect Wallet
      </button>
    </div>
  </div>
</template>

<script>
('use strict');

// Imports.
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { formatAddress } from '/src/utility/format';
// Component imports.
import WalletModal from '../layout/WalletModal.vue';
import MobileMenu from './MobileMenu.vue';

// Icon imports.
import Ethereum from '/src/components/common/Ethereum.vue';

// Set up the default header component.
export default {
  name: 'Header',
  components: {
    WalletModal,
    MobileMenu,
    Ethereum
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const mobileMenuIsVisible = ref(false);

    const profileAddressLabel = computed(() => {
      console.log(store);
      return formatAddress(store.state.ethers.address);
    });

    // Methods to hide/show wallet modal.
    const showWalletModalDisplay = () => {
      store.dispatch('alert/showWalletModal');
    };
    const hideWalletModalDisplay = () => {
      store.dispatch('alert/hideWalletModal');
    };

    // Methods to connect/disconnect wallet.
    const connect = () => {
      store.dispatch('ethers/connectWallet');
    };
    const disconnect = () => {
      store.dispatch('ethers/disconnectWallet');
    };

    const routeTo = path => {
      router.push(path);
    };

    const openUrl = url => {
      window.open(url, '_blank');
    };

    const showMobileMenu = () => {
      // Disable scrolling on window.
      window.onscroll = function() {
        window.scrollTo(0, 0);
      };
      mobileMenuIsVisible.value = true;
    };

    return {
      store,
      profileAddressLabel,
      mobileMenuIsVisible,
      showWalletModalDisplay,
      hideWalletModalDisplay,
      connect,
      disconnect,
      routeTo,
      openUrl,
      showMobileMenu
    };
  }
};
</script>

<style scoped lang="scss">
.navBar {
  background-color: black;
  padding: 20px;

  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .logo {
      margin-right: auto;
      margin-bottom: 0px;
      cursor: pointer;
      transition: all 300ms ease;
      display: none;

      &:active {
        transform: scale(0.95);
      }

      img {
        width: 150px;
      }
    }

    .link {
      display: flex;
      padding: 15px 20px;
      align-items: center;
      border-radius: 40px;
      transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      border: none;
      height: fit-content;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }
    }

    .line {
      border-bottom: 2px solid white;
      width: 100px;
    }

    .menuIcon {
      width: 30px;
      height: 30px;
      grid-template-columns: auto;
      grid-row-gap: 5px;
      color: white;
      padding: 20px;
      border-radius: 50%;
      display: none;

      .line {
        border-bottom: 2px solid white;
        width: 30px;
        height: 10px;
        box-sizing: border-box;
        &:last-child {
          margin-bottom: 10px;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }
    }

    .connect {
      box-shadow: inset 0 0 0 1px rgb(128, 128, 128);
    }

    .address {
      right: 20px;
      display: flex;
      padding: 15px 20px;
      align-items: center;
      border-radius: 40px;
      transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      background-color: transparent;
      border: none;
      width: fit-content;
      position: relative;
      margin-left: 20px;

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }

      .svg-icon {
        margin-left: 10px;
      }

      .profile-dropdown {
        position: absolute;
        top: 50px;
        right: 0px;
        width: 140px;
        padding: 10px;
        border-radius: 34px;
        background-color: #1b2050;
        font-size: 14px;
        z-index: 10;

        .dropdown-item {
          padding: 10px;
          padding-left: 15px;
          display: flex;
          align-items: center;
          border-radius: 24px;
          transition: background-color 0.2s ease;

          &:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.1);
          }

          .item-text {
            font-size: 16px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.homeNavBar {
  background: transparent;
  position: absolute;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
}

.wallet-modal-container {
  position: fixed;
  z-index: 9999;
}

.header {
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 5px;

  padding: 5px 30px;
  justify-items: center;
  position: relative;
  z-index: 99;
}

.header > img {
  height: 40px;
  grid-column-start: 2;
  transition: ease 0.2s;
}

.header > img:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.header > img:active {
  transform: scale(0.95);
}

.wallet_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 50px;
  width: 190px;
  background: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.wallet_button:hover {
  cursor: pointer;
  background: var(--background-color);
  color: var(--text-color);
}

.wallet_button:active {
  transform: scale(0.9);
}

.profile_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 0px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 40px;
  width: 160px;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.profile_button:hover {
  cursor: pointer;
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.profile_button:active {
  background-color: rgba(var(--purple-accent-rgb), 0.2);
}

.profile_button > p {
  margin-right: 10px;
  margin-left: 10px;
  color: var(--text-color);
  font-size: 14px;
}

.profile-dropdown {
  position: absolute;
  top: 70px;
  right: 10px;
  width: 160px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.2);
  border-radius: 34px;
  background-color: black;
  font-size: 14px;
  z-index: 10;
}

.dropdown-item {
  color: var(--text-color);
  padding: 10px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  cursor: pointer;
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.dropdown-item > p {
  margin-left: 10px;
  margin-bottom: 3px;
}

.svg-icon {
  margin-top: -5px;
  margin-left: -5px;
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
}

// Animations
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: all 1s ease;
  transition-delay: 0.5s;
}

.slide-enter-from
// .slide-leave-to
 {
  transform: translateY(-100px);
}

@media (max-width: 1050px) {
  .navBar .container {
  }
  .navBar .container .link {
    display: none;
  }

  .navBar .container .address {
    display: none;
  }

  .navBar .container .menuIcon {
    display: grid;
  }

  .navBar .container .logo {
    display: initial;
    width: fit-content;
    img {
      max-height: 49px;
    }
  }
}
</style>
