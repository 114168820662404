// Imports.
import { createRouter, createWebHistory } from 'vue-router';

// Page imports.
import Home from '../pages/home/Home.vue';
import NotFound from '../pages/not-found/NotFound.vue';
// Create routes.
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  { path: '/:pathMatch(.*)*', component: NotFound }
];

// Set up the Vue router to operate in web history mode.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
export default router;
